var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && !_vm.authorized)?_c('div',[_c('div',{staticClass:"text-center mb-5"},[_c('h1',{staticClass:"mb-5"},[_c('strong',[_vm._v("Welcome to "+_vm._s(_vm.settings.logo))])])]),_c('div',{staticClass:"card",class:_vm.$style.container},[_vm._m(0),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              initialValue: '',
              rules: [{ required: true, message: 'Please input your username!' }],
            } ]),expression:"[\n            'email',\n            {\n              initialValue: '',\n              rules: [{ required: true, message: 'Please input your username!' }],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"Email"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              initialValue: '',
              rules: [{ required: true, message: 'Please input your Password!' }],
            } ]),expression:"[\n            'password',\n            {\n              initialValue: '',\n              rules: [{ required: true, message: 'Please input your Password!' }],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"Password","type":"password"}})],1),_c('a-button',{staticClass:"text-center w-100",attrs:{"disabled":_vm.loading,"type":"primary","htmlType":"submit","size":"large","loading":_vm.loading}},[_c('strong',[_vm._v("Sign in")])])],1),_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/forgot-password"}},[_vm._v("Forgot Password?")])],1),_c('div',{staticClass:"text-center pt-2 mb-auto"},[_c('span',{staticClass:"mr-2"},[_vm._v("Don't have an account?")]),_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/register"}},[_vm._v("Sign up")])],1)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-dark font-size-24 mb-3"},[_c('strong',[_vm._v("Sign in to your account")])])}]

export { render, staticRenderFns }